import React from 'react';
import styled from 'styled-components';
import { plainTextStyle, header6 } from '@westwing/ui-kit/typography';
import { AspectRatio, Ratios } from '@westwing/ui-kit/AspectRatio';
import BellaHybridLink from '../Links/BellaHybridLink';
import { containerStyles } from '.';

const StyledContainer = styled.div`
    ${containerStyles}
`;

const StyledWrapper = styled.div`
    ${AspectRatio}
    display: flex;
`;

const StyledTitle = styled.span`
    ${header6}
    color: ${({ theme }) => theme.colors.charcoal100};
    text-align: center;
`;

const StyledCTA = styled.span`
    ${plainTextStyle}
    color: ${({ theme }) => theme.colors.charcoal100};
    margin-top: 5px;
    letter-spacing: normal;
    text-decoration: underline;
`;

const Card = styled(BellaHybridLink)`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.skeleton01};
    width: 100%;
`;

export interface LastSlideCardProps {
    link: string;
    title: string;
    ctaText: string;
    ratio: Ratios;
    onClickAction?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    'data-testid'?: string;
}

const LastSlideCard = ({ link, title, ctaText, ratio, onClickAction, ...rest }: LastSlideCardProps) => (
    <StyledContainer data-testid={rest['data-testid']}>
        <StyledWrapper ratio={ratio}>
            <Card to={link} onClick={onClickAction}>
                <StyledTitle>{title}</StyledTitle>
                <StyledCTA>{ctaText}</StyledCTA>
            </Card>
        </StyledWrapper>
    </StyledContainer>
);

export default LastSlideCard;
