import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showOverlay } from 'Client/redux/header/overlays/actions';
import {
    fetchLooksWishlistAction,
    addToLooksWishlistAction,
    removeFromLooksWishlistAction,
    updateLooksWishlistQueue,
} from 'Client/redux/looksWishlist/actions';
import { ADD_QUEUED_LOOK_TO_WISHLIST, REMOVE_QUEUED_LOOK_FROM_WISHLIST } from 'Client/redux/looksWishlist/types';
import StateInterface from 'Client/redux/types';
import { OverlayId } from 'Client/components/Overlays/types';
import { useSegmentTracking } from 'Common/segment';

export interface WishListNotificationMessages {
    successMessage: string;
    errorMessage: string;
}

const useLooksWishlist = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector<StateInterface, boolean>(state => state.appShellLogin.isLoggedIn);
    const looks = useSelector<StateInterface, string[]>(state => state.header.looksWishlist?.looks);
    const segmentTracker = useSegmentTracking();

    const dispatchdUpdateLooksWishlistQueue = (...args: Parameters<typeof updateLooksWishlistQueue>) =>
        dispatch(updateLooksWishlistQueue(...args));
    const dispatchShowOverlay = (...args: Parameters<typeof showOverlay>) => dispatch(showOverlay(...args));
    const dispatchAddToLooksWishlistAction = (...args: Parameters<typeof addToLooksWishlistAction>) =>
        dispatch(addToLooksWishlistAction(...args));
    const dispatchRemoveFromLooksWishlistAction = (...args: Parameters<typeof removeFromLooksWishlistAction>) =>
        dispatch(removeFromLooksWishlistAction(...args));

    const fetch = () => {
        if (isLoggedIn) {
            dispatch(fetchLooksWishlistAction());
        }
    };

    const isInWishlist = (lookSlug: string): boolean => (looks || []).includes(lookSlug);

    const add = (lookName: string, lookSlug: string, notificationMessages?: WishListNotificationMessages): boolean => {
        if (!isLoggedIn) {
            dispatchdUpdateLooksWishlistQueue(ADD_QUEUED_LOOK_TO_WISHLIST, lookSlug);
            dispatchShowOverlay(OverlayId.LOGIN_AND_REGISTER);
            return false;
        }
        dispatchAddToLooksWishlistAction(lookSlug, notificationMessages);
        segmentTracker.track('Look Added to Looks Wishlist', {
            look_name: lookName,
        });
        return true;
    };

    const remove = (lookName: string, lookSlug: string): boolean => {
        if (!isLoggedIn) {
            dispatchdUpdateLooksWishlistQueue(REMOVE_QUEUED_LOOK_FROM_WISHLIST, lookSlug);
            return false;
        }
        dispatchRemoveFromLooksWishlistAction(lookSlug);
        segmentTracker.track('Look Removed from Looks Wishlist', {
            look_name: lookName,
        });
        return true;
    };

    const isInLooksWishlist = useCallback(isInWishlist, [looks]);
    const fetchLooksWishlist = useCallback(fetch, [isLoggedIn]);
    const addToLooksWishlist = useCallback(add, [isLoggedIn]);
    const removeFromLooksWishlist = useCallback(remove, [isLoggedIn]);

    return { fetchLooksWishlist, isInLooksWishlist, addToLooksWishlist, removeFromLooksWishlist };
};

export default useLooksWishlist;
