import React, { MouseEvent, useState, useEffect } from 'react';
import WishlistIconButton from '@westwing/ui-kit/WishlistIconButton';
import useLooksWishlist from './hooks/useLooksWishlist';
import useTranslator from 'Client/hooks/useTranslator';

export interface Props {
    lookSlug: string;
    lookName: string;
    className?: string;
    testId?: string;
    onClick?: (selected: boolean) => void;
}

export const WishlistBadge = ({ lookName, lookSlug, className, testId, onClick }: Props) => {
    const { isInLooksWishlist, addToLooksWishlist, removeFromLooksWishlist } = useLooksWishlist();
    const [isSelected, setSelected] = useState(isInLooksWishlist(lookSlug));
    const t = useTranslator();

    useEffect(() => {
        setSelected(isInLooksWishlist(lookSlug));
    }, [lookSlug, isInLooksWishlist]);

    const handleClick = (e: MouseEvent<HTMLElement>, selected: boolean): void => {
        if (onClick) {
            onClick(selected);
        }

        const isAllowed = selected
            ? addToLooksWishlist(lookName, lookSlug, {
                  successMessage: t('Looks_Add Look to Wishlist notification'),
                  errorMessage: t('wishlist_Sorry, wishlist is currently not available. Please try again later'),
              })
            : removeFromLooksWishlist(lookName, lookSlug);
        setSelected(!isAllowed ? false : selected);
    };

    return (
        <WishlistIconButton
            onClick={e => handleClick(e, !isSelected)}
            isSelected={isSelected}
            className={className}
            data-testid={testId}
            key={lookSlug}
        />
    );
};
