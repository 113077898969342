import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { forMidDesktop, forIE, forTablet, forWideScreens } from '@westwing/ui-kit/breakPoints';
import { scroll } from '@westwing/ui-kit/utils';
import HorizontalScrollableElement, { CTAOptions } from 'Client/components/ScrollableElement';
import { Direction } from '@westwing/ui-kit/Icons/StyledIcons';

const WideSliderWrapper = styled.div<{ showBorder?: boolean; noSideMarginsForWideScreen?: boolean }>`
    ${({ showBorder, theme }) =>
        showBorder &&
        css`
            border-top: 1px solid ${theme.colors.charcoal20};
        `}

    ${({ noSideMarginsForWideScreen }) =>
        noSideMarginsForWideScreen &&
        forWideScreens`
        margin-left: -20px;
        margin-right: -20px;
    `}

    ${forIE`
         height: 600px;
    `}
`;

const SliderWrapper = styled(HorizontalScrollableElement)`
    padding-top: 32px;
    padding-left: 40px;

    ${forMidDesktop`
        padding-left: 13px;
        margin: 0;
    `}
`;

export const containerStyles = css`
    margin-right: 10px;
    flex: 1 0 175px;
    &:last-child {
        flex-basis: 185px;
        padding-right: 10px;
    }
    ${forTablet`
        margin-right: 0;
        &:not(:first-child) {
            margin-left: 10px;
        }
    `}
    ${forMidDesktop`
        flex-grow: 0;
        flex-basis: 212px;
        &:last-child {
            flex-basis: 212px;
            padding-right: 0;
        }
    `}
    ${forWideScreens`
        flex-basis: 303px;
        &:last-child {
            flex-basis: 303px;
            padding-right: 0;
        }
    `}
`;

const StyledHorizontalScrollContainer = styled.div`
    display: flex;
    flex-direction: row;
    scroll-behavior: smooth;
    max-width: 100vw;
    padding: 0 10px 0 40px;
    ${scroll('x', true)}

    ${forTablet`
        padding-bottom: 0;
    `}

    ${forMidDesktop`
        padding-left: 0;
        padding-right: 0;
        max-width: 100vw;
    `}
`;

interface ContentInfusionSliderProps {
    title: string;
    ctaOptions?: CTAOptions;
    onArrowClicks?: (direction?: Direction) => void;
    showBorder?: boolean;
    noSideMarginsForWideScreen?: boolean;
    'data-testid'?: string;
}

const ContentInfusionSlider = ({
    title,
    ctaOptions,
    onArrowClicks,
    children,
    showBorder = true,
    noSideMarginsForWideScreen = true,
    ...rest
}: PropsWithChildren<ContentInfusionSliderProps>) => (
    <WideSliderWrapper showBorder={showBorder} noSideMarginsForWideScreen={noSideMarginsForWideScreen}>
        <SliderWrapper
            title={title}
            arrowClickTrackFunction={onArrowClicks}
            showHeader
            showNavArrows
            ctaOptions={ctaOptions}
            centerOffset={100}
        >
            <StyledHorizontalScrollContainer data-testid={rest['data-testid']}>
                {children}
            </StyledHorizontalScrollContainer>
        </SliderWrapper>
    </WideSliderWrapper>
);

export default ContentInfusionSlider;
