import React from 'react';
import styled, { css } from 'styled-components';
import { forDesktop, forTablet, forWideScreens, forIE, forMidWideScreens } from '@westwing/ui-kit/breakPoints';
import TextBox from '@westwing/ui-kit/TextBox';
import { AspectRatio, Ratios } from '@westwing/ui-kit/AspectRatio';
import { textLinesLimitClipCss } from '@westwing/ui-kit/typography';
import BellaHybridLink from 'Client/components/Generic/Links/BellaHybridLink';
import { LazyHybridImage, LazyImageProps } from 'Client/components/Generic/HybridImage';

export const sliderContainerStyles = css<{ isLandscape?: boolean }>`
    margin-right: 8px;
    flex: 1 0 175px;

    ${forTablet`
        margin-right: 0;

        &:not(:first-child) {
            margin-left: 12px;
        }
    `}
    ${forDesktop`
        flex-grow: 0;
        flex-basis: 212px;
    `}
    ${forWideScreens`
        flex-basis: 303px;
    `}

    ${({ isLandscape }) =>
        isLandscape &&
        css`
            flex-basis: 350px;

            ${forTablet`
                flex-basis: 384px;
            `}

            ${forDesktop`
                flex-basis: 422px;
            `}

            ${forWideScreens`
                flex-basis: 606px;
            `}
        `}
`;

const StyledLinkContainer = styled(BellaHybridLink)<{ isLandscape?: boolean }>`
    ${sliderContainerStyles}
    position: relative;

    ${forMidWideScreens`
        &:last-child {
            padding-right: 0;
        }
    `}
`;

const LazyStyledImage = styled(LazyHybridImage)`
    ${AspectRatio}
    position: relative;
    object-fit: cover;
    overflow: hidden;

    picture {
        position: absolute;
    }
`;

type BorderProps = {
    showBorder?: boolean;
    borderColor?: string;
    isLandscape?: boolean;
};

const BorderedLazyStyledImage = styled(LazyStyledImage)<BorderProps>`
    picture {
        ${({ showBorder }) =>
            showBorder &&
            css`
                background: white;
            `}

        img {
            ${({ showBorder, borderColor, isLandscape }) =>
                showBorder &&
                isLandscape &&
                css`
                    margin-left: 12px;
                    margin-bottom: 8px;
                    box-shadow: 0 8px 0px 0px ${borderColor}, -12px 8px 0px 0px ${borderColor};
                    width: calc(100% - 12px);

                    ${forDesktop`
                        margin-left: 20px;
                        margin-bottom: 14px;
                        box-shadow: 0 14px 0px 0px ${borderColor}, -20px 14px 0px 0px ${borderColor};
                        width: calc(100% - 20px);
                    `}
                `}

            ${({ showBorder, borderColor, isLandscape }) =>
                showBorder &&
                !isLandscape &&
                css`
                    margin-left: 10px;
                    margin-bottom: 15px;
                    box-shadow: 0 15px 0px 0px ${borderColor}, -15px 15px 0px 0px ${borderColor};
                    width: calc(100% - 10px);

                    ${forDesktop`
                        margin-left: 12px;
                        margin-bottom: 12px;
                        box-shadow: 0 15px 0px 0px ${borderColor}, -15px 15px 0px 0px ${borderColor};
                        width: calc(100% - 12px);
                    `}
                `}
        }
    }
`;

const StyledInfusionTextBox = styled(TextBox)`
    padding-top: 8px;
    max-width: 100%;
`;

export const Title = styled(TextBox.Header)`
    ${textLinesLimitClipCss(1)}
    ${forIE`
        display: block;
    `}
`;

const StyledCta = styled(TextBox.Cta)`
    margin-bottom: 40px;
`;

export interface SliderTileItem extends LazyImageProps {
    title: string;
    ctaText: string;
    targetUrl: string;
    onClick: () => void;
    borderColor?: string;
    isLandscape?: boolean;
    trackImpressionFunction?: Function;
}

interface SliderTileProps {
    card: SliderTileItem;
    ratio: Ratios;
    showBorder?: boolean;
}

const SliderTile = ({
    card: { onClick, ...card },
    ratio,
    showBorder,
    children,
}: React.PropsWithChildren<SliderTileProps>) => (
    <StyledLinkContainer to={card.targetUrl} onClick={onClick} isLandscape={card.isLandscape}>
        <BorderedLazyStyledImage {...card} ratio={ratio} showBorder={showBorder} />
        {children}
        <StyledInfusionTextBox>
            <Title>{card.title}</Title>
            <StyledCta>{card.ctaText}</StyledCta>
        </StyledInfusionTextBox>
    </StyledLinkContainer>
);

export default SliderTile;
